import * as Sentry from '@sentry/vue'
import { ExtraErrorData } from '@sentry/integrations'
import router from '../router'
import Vue from 'vue'

Sentry.init({
    Vue,
    dsn: 'https://5d3234d35483a81e96a9ccda21944fb1@sentry.it-team.cloud/99',
    integrations: [
        new Sentry.BrowserTracing({
            // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
            tracePropagationTargets: ['localhost', 'localhost:8050'],
            routingInstrumentation: Sentry.vueRouterInstrumentation(router)
        }),
        new ExtraErrorData()
    ],
    maxBreadcrumbs: 50,
    beforeBreadcrumb(breadcrumb) {
        if (!breadcrumb) {
            return null
        }

        if (breadcrumb.category === 'ui.click') {
            const regex = new RegExp('name="(.*?)"')
            const neki = regex.exec(breadcrumb.message)

            if (neki.length >= 2) {
                Sentry.addBreadcrumb({
                    category: 'Custom',
                    message: `Clicked button ${neki[1]}`,
                    level: 'info'
                })
            }

            return null
        } else if (breadcrumb.category === 'xhr') {
            if (breadcrumb.data && breadcrumb.data.url) {
                const url = breadcrumb.data.url

                const urlsToIgnore = []

                if (urlsToIgnore.includes(url)) {
                    return null
                }

            }
        }

        return breadcrumb
    },
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0
})

Vue.prototype.$sentry = Sentry